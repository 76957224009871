
export const initVideoTriggers = () => {

  window.addEventListener("resize", function () {
    const video = document.querySelector(".video--open");
    if (video) {
      resetVideoPreview(video);
    }
  });

  const resetVideoPreview = (video) => {
    video.classList.remove("video--open");
    var videoIframe = video.querySelector(".video__embed iframe");
    video.style.top = null;
    video.style.left = null;
    video.style.width = null;
    video.style.height = null;

    setTimeout(() => {
      video.classList.remove("video--ready");
      video.classList.remove("video--ontop");
      if (videoIframe.dataset.src) {
        videoIframe.src = '';
      } else if (videoIframe.hasAttribute("src")) {
        var videoSource = videoIframe.getAttribute("src");
        videoIframe.setAttribute("src", videoSource);
      }
    }, 500);
  }

  if (document.querySelectorAll(".video").length > 0) {
    document.querySelectorAll(".video").forEach(function (video) {
      const trigger = video.querySelector('.video__trigger');
      trigger.addEventListener("click", function (e) {
        e.preventDefault();

        const maxWidth = 1922;
        const maxHeight = maxWidth * (9 / 16) > window.innerHeight ? window.innerHeight : maxWidth * (9 / 16);
        const videoMinWidth = window.innerWidth < 768 ? window.innerWidth : window.innerWidth * 0.8;
        const videoEmbedIframe = video.querySelector('.video__embed iframe');
        const videoPos = video.getBoundingClientRect();

        if (videoEmbedIframe.dataset.src) {
          videoEmbedIframe.src = videoEmbedIframe.dataset.src;
        }

        video.classList.add("video--open", "video--ontop");
        setTimeout(() => {
          video.classList.add("video--ready");
        }, 500);

        let videoWidth = videoMinWidth > maxWidth ? maxWidth : videoMinWidth;
        const videoHeight = videoWidth * (9 / 16) > maxHeight ? maxHeight : videoWidth * (9 / 16);
        if (videoWidth * (9 / 16) > maxHeight) {
          videoWidth = videoHeight * (16 / 9);
        }
        const videoLeft = ((window.innerWidth - videoWidth) / 2) - videoPos.left;
        //const videoTop = ((window.innerHeight - videoHeight) / 2) - videoPos.top;
        const videoTop = (videoPos.height - videoHeight) / 2;

        video.style.width = videoWidth + 'px';
        video.style.height = videoHeight + 'px';
        video.style.left = videoLeft + 'px';
        video.style.top = videoTop + 'px';

        const videoLocation = window.scrollY + video.getBoundingClientRect().top - (window.innerHeight / 2) + (video.offsetHeight / 2);
        window.scrollTo({
          top: videoLocation,
          left: 0,
          behavior: "smooth"
        });
      });

      video.querySelector(".video__bg").addEventListener("click", function (e) {
        e.preventDefault();
        if (video) {
          resetVideoPreview(video);
        }
      });

      window.addEventListener("scroll", function () {
        if (!video.classList.contains("video--open")) return;
        //console.log((video.getBoundingClientRect().top - this.window.innerHeight) + ' / ' + video.getBoundingClientRect().bottom);
        if ((video.getBoundingClientRect().top - this.window.innerHeight) < -100 && video.getBoundingClientRect().bottom > 100) {
          //console.log('Video is in view');
        } else {
          resetVideoPreview(video);
        }
      });
    });
  }
};

